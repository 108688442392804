import request from '@/utils/request'

// 会员后台充值分页列表
export function vipFillList(params) {
    return request({
        url: '/api/system/vip/bill/vipFill/list',
        method: 'get',
        params
    })
}

// 会员充值分页列表
export function vipRechargeList(params) {
    return request({
        url: '/api/system/vip/bill/vipFill/getDetail',
        method: 'get',
        params: params
    })
}


// 新增会员后台充值
export function vipFillSave(data) {
    return request({
        url: '/api/system/vip/bill/vipFill/save',
        method: 'post',
        data
    })
}
// 修改会员后台充值
export function vipFillUpdate(data) {
    return request({
        url: '/api/system/vip/bill/vipFill/update',
        method: 'put',
        data: data
    })
}
// 审核/反审核
export function vipFillUpdateStatus(data) {
    return request({
        url: '/api/system/vip/bill/vipFill/updateBillStatus',
        method: 'post',
        data: data
    })
}

// 获取会员后台充值详细信息
export function vipFillGetDetail(billId) {
    return request({
        url: '/api/system/vip/bill/vipFill/getDetail',
        method: 'get',
        params:{
            billId
        }
    })
}

//会员充值删除
export function vipFillDel(billIds) {
    return request({
        url: '/api/system/vip/bill/vipFill/remove',
        method: 'delete',
        data:billIds
    })
}


//会员单据反充值
export function vipReverseFill(billIds) {
    return request({
        url: '/api/system/vip/bill/vipFill/reverseRecharge',
        method: 'post',
        data:billIds
    })
}

// 会员列表
export function vipCardBasicList(query) {
    return request({
        url: '/api/system/vip/base/vipcard/basicList',
        method: 'get',
        params: query
    })
}